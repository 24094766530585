import React from "react";
import TransferRequest from "../components/TransferRequest";
import EditContact from "../components/EditContact";
import "../css/Panel.css";
import { post, postFile } from "../utils/connection";

const statusOptionList = [
  {
    value: "",
    label: "",
  },
  {
    value: "Pendiente",
    label: "Pendiente",
  },
  {
    value: "Tramitado",
    label: "Tramitado",
  },
  {
    value: "Error",
    label: "Error",
  },
];

class Panel extends React.Component {
  constructor(props) {
    super(props);
    this.token = localStorage.getItem("token");
    this.state = {
      estado: "Pendiente",
      DNI: "",
      codigo: "",
      transferRequestList: [],
      selectedTransferRequestList: [],
      editContact: false,
      selectAll: false,
      contactToEdit: null,
      toggleSearchCertificate: false,
      closeSession: false,
    };
  }

  componentDidMount = () => {
    this.loadTransferRequests("Pendiente");
  };

  openContactData = (item) => {
    this.setState({
      editContact: true,
      contactToEdit: item,
    });
    setTimeout(() => {
      document
        .getElementById("contactPanel")
        .scrollIntoView({ behavior: "smooth" });
    }, 10);
  };

  closeContactData = () => {
    this.setState({
      editContact: false,
      contactToEdit: null,
    });
  };

  saveEditedContact = async (contact) => {
    const transferRequestListAux = this.state.transferRequestList;
    const contactAux = transferRequestListAux.find((c) => {
      return c.idSolicitud === contact.idSolicitud;
    });
    contactAux.nombre = contact.nombre;
    contactAux.apellidos = contact.apellidos;
    contactAux.dni = contact.dni;
    contactAux.telefono = contact.telefono;
    contactAux.email = contact.email;
    contactAux.iban = contact.iban;
    contactAux.certificadoDonacion = contact.certificadoDonacion;
    contactAux.direccion = contact.direccion;
    contactAux.localidad = contact.localidad;
    contactAux.provincia = contact.provincia;
    contactAux.cp = contact.cp;
    this.setState({
      transferRequestList: transferRequestListAux,
    });
    if (
      contactAux.nombre !== "" &&
      contactAux.apellidos !== "" &&
      contactAux.dni !== "" &&
      contactAux.telefono !== "" &&
      contactAux.email !== "" &&
      contactAux.iban !== "" &&
      (contactAux.certificadoDonacion === "0" ||
        (contactAux.certificadoDonacion === "1" &&
          contactAux.direccion !== "" &&
          contactAux.localidad !== "" &&
          contactAux.provincia !== "" &&
          contactAux.cp !== ""))
    ) {
      await post("ModificarSolicitudTransferencia", {
        token: this.token,
        idSolicitud: contactAux.idSolicitud,
        nombre: contactAux.nombre,
        apellidos: contactAux.apellidos,
        dni: contactAux.dni,
        telefono: contactAux.telefono,
        email: contactAux.email,
        iban: contactAux.iban,
        certificadoDonacion: contactAux.certificadoDonacion,
        direccion: contactAux.direccion,
        localidad: contactAux.localidad,
        provincia: contactAux.provincia,
        cp: contactAux.cp,
      });
    }
  };

  changeStatus = (e) => {
    const stateFound = statusOptionList.find((s) => {
      return s.value === e.target.value;
    });
    this.setState({
      estado: stateFound.value,
      DNI: "",
      codigo: "",
      selectAll: false,
      toggleSearchCertificate: false,
      selectedTransferRequestList: [],
    });
    if (stateFound.value !== "") {
      this.loadTransferRequests(stateFound.value);
    }
  };

  changeTransferRequestStatus = async (status, key) => {
    const transferRequestListAux = this.state.transferRequestList;
    const elementAux = transferRequestListAux[key];
    elementAux.estado = status;
    transferRequestListAux[key] = elementAux;
    this.setState({
      transferRequestList: transferRequestListAux,
    });
    await post("ModificarEstadoTransferencia", {
      token: this.token,
      estado: status,
      idSolicitud: elementAux.idSolicitud,
    });
  };

  changeAllTransferRequestSelection = (selection) => {
    let transferRequestListAux = this.state.transferRequestList;
    transferRequestListAux = transferRequestListAux.map((e) => {
      e.selected = selection;
      return e;
    });
    this.setState({
      transferRequestList: transferRequestListAux,
    });
  };

  changeTransferRequestSelection = (key) => {
    const transferRequestListAux = this.state.transferRequestList;
    const selectedTransferRequestListAux = [];
    const elementAux = transferRequestListAux[key];
    elementAux.selected = !elementAux.selected;
    transferRequestListAux[key] = elementAux;
    transferRequestListAux.forEach((e) => {
      if (e.selected === true) {
        selectedTransferRequestListAux.push(e);
      }
    });
    this.setState({
      transferRequestList: transferRequestListAux,
      selectedTransferRequestList: selectedTransferRequestListAux,
    });
  };

  loadTransferRequests = async (status) => {
    let response = await post("ListarSolicitudesTransferencia", {
      token: this.token,
      estado: status,
      DNI: this.state.DNI,
      codigo: this.state.codigo,
      certificadoDonacion: this.state.toggleSearchCertificate ? 1 : 0,
    });
    response = response.map((e) => {
      e.selected = false;
      return e;
    });
    this.setState({
      transferRequestList: response,
    });
  };

  codeChange = (event) => {
    this.setState({
      codigo: event.target.value,
      DNI: "",
      selectAll: false,
    });
  };

  selectEmptyStatus = () => {
    const e = {
      target: {
        value: "",
      },
    };
    this.changeStatus(e);
  };

  searchByCode = async () => {
    if (this.state.codigo !== "") {
      let response = await post("ListarSolicitudesTransferencia", {
        token: this.token,
        estado: "",
        DNI: "",
        codigo: this.state.codigo,
        certificadoDonacion: 0,
      });
      response = response.map((e) => {
        e.selected = false;
        return e;
      });
      this.setState({
        transferRequestList: response,
        selectAll: false,
        selectedTransferRequestList: [],
      });
      this.selectEmptyStatus();
    }
  };

  dniChange = (event) => {
    this.setState({
      DNI: event.target.value,
      codigo: "",
    });
  };

  resetSelectedItem = () => {
    let transferRequestListAux = this.state.transferRequestList;
    transferRequestListAux = transferRequestListAux.map((e) => {
      e.selected = false;
      return e;
    });
    this.setState({
      transferRequestList: transferRequestListAux,
      selectedTransferRequestList: [],
    });
  };

  searchByDNI = async () => {
    if (this.state.DNI !== "") {
      let response = await post("ListarSolicitudesTransferencia", {
        token: this.token,
        estado: "",
        DNI: this.state.DNI,
        codigo: "",
        certificadoDonacion: 0,
      });
      response = response.map((e) => {
        e.selected = false;
        return e;
      });
      this.setState({
        transferRequestList: response,
        selectAll: false,
        selectedTransferRequestList: [],
      });
      this.selectEmptyStatus();
    }
  };

  searchByCertificateOK = async () => {
    let response = await post("ListarSolicitudesTransferencia", {
      token: this.token,
      estado: "",
      DNI: "",
      codigo: "",
      certificadoDonacion: 1,
    });
    response = response.map((e) => {
      e.selected = false;
      return e;
    });
    this.setState({
      transferRequestList: response,
    });
  };

  markRequestsAsProcessed = async () => {
    const transferRequestListAux = [];
    for (let i = 0; i < this.state.selectedTransferRequestList.length; i++) {
      await post("ModificarEstadoTransferencia", {
        token: this.token,
        estado: "Tramitado",
        idSolicitud: this.state.selectedTransferRequestList[i].idSolicitud,
      });
    }
    this.state.transferRequestList.forEach((e) => {
      if (e.selected === false) {
        transferRequestListAux.push(e);
      }
    });
    this.setState({
      transferRequestList: transferRequestListAux,
      selectAll: false,
    });
    const e = {
      target: {
        value: "Pendiente",
      },
    };
    this.changeStatus(e);
    this.loadTransferRequests("Pendiente");
  };

  exportRequests = async () => {
    const iDsSelected = [];
    this.state.selectedTransferRequestList.forEach((e) => {
      iDsSelected.push(e.idSolicitud);
    });
    let response = await postFile("ExportarSolicitudes", {
      token: this.token,
      solicitudes: iDsSelected,
    });
    this.downloadTxtFile(response);
  };

  downloadTxtFile = (text) => {
    const element = document.createElement("a");
    element.setAttribute("id", "file01");
    const file = new Blob([text], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "download.csv";
    document.body.appendChild(element);
    element.click();
    element.remove();
  };

  render() {
    return (
      <div className="bodycontent administradorpage Panel-administradorpage">
        <div className="userheader administradorpage">
          <img
            src="images/Logo-Farmaceuticos-Sin-Fronteras-de-Espana.jpg"
            loading="lazy"
            srcSet="images/Logo-Farmaceuticos-Sin-Fronteras-de-Espana-p-500.jpg 500w, images/Logo-Farmaceuticos-Sin-Fronteras-de-Espana-p-800.jpg 800w, images/Logo-Farmaceuticos-Sin-Fronteras-de-Espana.jpg 800w"
            sizes="(max-width: 479px) 50vw, (max-width: 767px) 140px, (max-width: 991px) 160px, 180px"
            alt=""
            className="headerlogo"
          />
          <h1 className="loteriatitle">
            Lotería Navidad<br></br>
            <span className="text-span-2">ADMINISTRADOR</span>
          </h1>
          <h1 className="loteriatitle nomargintop mobiledisplay">
            Lotería<br></br>Navidad
          </h1>
          <div
            className="exitContainer"
            onClick={() => {
              this.setState({
                closeSession: true,
                editContact: false,
              });
            }}
          >
            <span>Cerrar sesión</span>
          </div>
        </div>

        <div className="requestGlobal">
          <div
            className="Panel-blur-background"
            id="contactPanel"
            style={{
              display:
                this.state.editContact || this.state.closeSession
                  ? "flex"
                  : "none",
              height:
                this.state.editContact || this.state.closeSession
                  ? document.querySelector(".requestGlobal").offsetHeight + "px"
                  : "100%",
            }}
          >
            {this.state.editContact === true && (
              <EditContact
                contact={this.state.contactToEdit}
                onClose={this.closeContactData}
                onSaveEditedContact={this.saveEditedContact}
              ></EditContact>
            )}
            {this.state.closeSession === true && (
              <div className="formblockdatosusuario w-form EditContact-formblockdatosusuario">
                <h5 className="formheader formheaderExtra1">
                  <strong>Cerrar </strong>sesión
                </h5>
                <div className="formdatosusuario">
                  <label
                    htmlFor="name"
                    className="comprobarcuponcontenttitle exitSessionMessage"
                  >
                    Va a cerrar la sesión actual. ¿Desea continuar?
                  </label>
                  <div className="EditContact-buttons">
                    <div
                      className="submit-button w-button"
                      onClick={() => {
                        localStorage.clear();
                        window.location = "/";
                      }}
                    >
                      <span>Continuar</span>
                    </div>
                    <div
                      className="submit-button w-button"
                      onClick={() => {
                        this.setState({
                          closeSession: false,
                        });
                      }}
                    >
                      <span>Cancelar</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="adminfiltrado">
            <div
              className="search-button w-button buttonAll"
              onClick={() => {
                this.loadTransferRequests("Pendiente");
                this.setState({
                  DNI: "",
                  codigo: "",
                  estado: "Pendiente",
                  toggleSearchCertificate: false,
                  selectAll: false,
                  selectedTransferRequestList: [],
                });
              }}
            >
              Restaurar filtros
            </div>
            <div className="form-block widthauto w-form">
              <div className="form displayflex">
                <label htmlFor="Estado" className="field-label adminview">
                  Filtrar por estado
                </label>
                <select
                  className="select-field w-select"
                  value={this.state.estado}
                  onChange={this.changeStatus}
                >
                  {statusOptionList.map((option, key) => (
                    <option key={key} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="search w-form">
              <label htmlFor="search" className="field-label adminview">
                Buscar por código
              </label>
              <input
                type="text"
                value={this.state.codigo}
                onChange={this.codeChange}
                className="search-input w-input inputExtra"
                maxLength="256"
                placeholder="Introduzca el código"
              ></input>
              <div
                className="search-button w-button"
                onClick={this.searchByCode}
              >
                Buscar
              </div>
            </div>
            <div className="search w-form">
              <label htmlFor="search-2" className="field-label adminview">
                Buscar por DNI
              </label>
              <input
                type="text"
                value={this.state.DNI}
                onChange={this.dniChange}
                className="search-input w-input inputExtra"
                maxLength="256"
                placeholder="Introduzca el DNI"
              ></input>
              <div
                className="search-button w-button"
                onClick={this.searchByDNI}
              >
                Buscar
              </div>
            </div>
            <div className="search w-form inputCertificate">
              <input
                type="checkbox"
                className="w-checkbox-input checkbox"
                value={this.state.toggleSearchCertificate}
                checked={this.state.toggleSearchCertificate}
                onChange={() => {
                  const toggleAux = !this.state.toggleSearchCertificate;
                  if (toggleAux) {
                    this.selectEmptyStatus();
                    this.searchByCertificateOK();
                  } else {
                    const e = {
                      target: {
                        value: "Pendiente",
                      },
                    };
                    this.changeStatus(e);
                    this.loadTransferRequests("Pendiente");
                  }
                  this.setState({
                    toggleSearchCertificate:
                      !this.state.toggleSearchCertificate,
                    selectAll: false,
                  });
                  this.resetSelectedItem();
                }}
              ></input>
              <label htmlFor="search-2" className="field-label adminview">
                Certificado Donación
              </label>
            </div>
          </div>
          {this.state.transferRequestList.length > 0 && (
            <div className="admintableheader">
              <div className="div">
                <div className="selectcliente w-form">
                  <label className="w-checkbox checkbox-field header">
                    <input
                      type="checkbox"
                      id="checkbox-2"
                      name="checkbox-2"
                      data-name="Checkbox 2"
                      className="w-checkbox-input checkbox"
                      value={this.state.selectAll}
                      checked={this.state.selectAll}
                      onChange={() => {
                        const selectAllAux = !this.state.selectAll;
                        const selectedTransferRequestListAux = [];
                        this.setState({
                          selectAll: !this.state.selectAll,
                        });
                        this.changeAllTransferRequestSelection(selectAllAux);
                        this.state.transferRequestList.forEach((e) => {
                          if (e.selected === true) {
                            selectedTransferRequestListAux.push(e);
                          }
                        });
                        this.setState({
                          selectedTransferRequestList:
                            selectedTransferRequestListAux,
                        });
                      }}
                    ></input>
                  </label>
                </div>
                <div className="tableheadertitle _01">Selección</div>
              </div>
              <div className="tableheadertitle _02">ID</div>
              <div className="tableheadertitle _03">Número</div>
              <div className="tableheadertitle _04">Código</div>
              <div className="tableheadertitle _05">Contacto</div>
              <div className="tableheadertitle _06">Estado</div>
            </div>
          )}
          {this.state.transferRequestList.map((item, key) => {
            return (
              <TransferRequest
                item={item}
                selectAll={this.state.selectAll}
                onChangeStatus={this.changeTransferRequestStatus}
                onChangeSelection={this.changeTransferRequestSelection}
                onClickContactName={() => {
                  this.openContactData(item);
                }}
                index={key}
                key={key}
              ></TransferRequest>
            );
          })}
          {this.state.transferRequestList.length === 0 && (
            <span className="bodytext notFoundMessage">
              <strong>No se han encontrado&nbsp;</strong> solicitudes de
              transferencia.
            </span>
          )}
          {this.state.transferRequestList.length > 0 && (
            <div className="admindescargar">
              <div className="button w-button" onClick={this.exportRequests}>
                Descargar fichero
              </div>
              <div
                className="button w-button"
                onClick={this.markRequestsAsProcessed}
              >
                Marcar seleccionados como Tramitados
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Panel;
