import React from "react";
import "../css/TransferRequest.css";

const statusOptionList = [
  {
    value: "Pendiente",
    label: "Pendiente",
  },
  {
    value: "Tramitado",
    label: "Tramitado",
  },
  {
    value: "Error",
    label: "Error",
  },
];

class TransferRequest extends React.Component {
  render() {
    const { onChangeStatus, onClickContactName, onChangeSelection } =
      this.props;
    return (
      <div
        className={`adminclientcomponent TransferRequest-adminclientcomponent ${
          this.props.index % 2 !== 0 ? "oddBackground" : ""
        }`}
      >
        <div className="selectcliente w-form">
          <label className="w-checkbox checkbox-field">
            <input
              type="checkbox"
              id="checkbox-2"
              name="checkbox-2"
              data-name="Checkbox 2"
              className="w-checkbox-input checkbox"
              value={this.props.item.selected}
              checked={this.props.item.selected}
              onChange={() => {
                onChangeSelection(this.props.index);
              }}
            />
            <span
              className="checkbox-label w-form-label"
              htmlFor="checkbox-2"
            ></span>
          </label>
        </div>
        <div className="idcliente">{this.props.item.idSolicitud}</div>
        <div className="bodytext">{this.props.item.numero}</div>
        <div className="codigocliente">{this.props.item.codigo}</div>
        <div className="contactocliente">
          <div className="infocontactocliente">
            <img
              src="images/usuario.svg"
              loading="lazy"
              width="18"
              alt=""
              className="iconcliente"
            />
            <div
              className="bodytext contactinfo bold nomargin TransferRequest-fullname"
              onClick={onClickContactName}
            >
              {this.props.item.apellidos}, {this.props.item.nombre}
            </div>
            <div className="infocontactocliente-copy">
              <img
                src="images/id-card.svg"
                loading="lazy"
                width="18"
                alt=""
                className="iconcard"
              />
              <div className="bodytext contactinfo">
                <strong className="bold-text-5">{this.props.item.dni}</strong>
              </div>
            </div>
          </div>
          <div className="infocontactocliente">
            <img
              src="images/mobile.svg"
              loading="lazy"
              width="18"
              alt=""
              className="iconcliente"
            />
            <div className="bodytext contactinfo">
              {this.props.item.telefono}
            </div>
            <div className="infocontactocliente-copy">
              <img
                src="images/email.svg"
                loading="lazy"
                width="18"
                alt=""
                className="iconcard"
              />
              <div className="bodytext contactinfo">
                {this.props.item.email}
              </div>
            </div>
          </div>
          {this.props.item.certificadoDonacion === "1" && (
            <div className="infocontactocliente">
              <img
                src="images/casa.svg"
                loading="lazy"
                width="18"
                alt=""
                className="iconcliente"
              />
              <div className="bodytext contactinfo">
                {this.props.item.direccion +
                  ", " +
                  this.props.item.localidad +
                  ", " +
                  this.props.item.provincia +
                  ", " +
                  this.props.item.cp}
              </div>
            </div>
          )}
          <div className="infocontactocliente TransferRequest-infocontactoclienteExtra">
            <img
              src="images/bank.svg"
              loading="lazy"
              width="18"
              alt=""
              className="iconcliente"
            />
            <div className="bodytext contactinfo">{this.props.item.iban}</div>
          </div>
          {this.props.item.certificadoDonacion === "1" && (
            <div className="infocontactocliente TransferRequest-infocontactoclienteExtra2">
              <img
                src="images/certificado.svg"
                loading="lazy"
                width="18"
                alt=""
                className="iconcliente"
              />
              <div className="bodytext contactinfo bodytextGreen">
                Solicita certificado de donación
              </div>
            </div>
          )}
        </div>
        <div className="estadocliente w-form">
          <select
            className="select-field w-select"
            value={this.props.item.estado}
            onChange={(e) => onChangeStatus(e.target.value, this.props.index)}
          >
            {statusOptionList.map((option, key) => (
              <option key={key} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }
}

export default TransferRequest;
