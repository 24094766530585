import React from "react";
import "../css/EditContact.css";

class EditContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre: this.props.contact.nombre,
      apellidos: this.props.contact.apellidos,
      dni: this.props.contact.dni,
      telefono: this.props.contact.telefono,
      email: this.props.contact.email,
      iban: this.props.contact.iban,
      ibanCodigoPais: this.props.contact.iban.split(" ").join("").slice(0, 2),
      ibanDigitoControl1: this.props.contact.iban
        .split(" ")
        .join("")
        .slice(2, 4),
      ibanEntidad: this.props.contact.iban.split(" ").join("").slice(4, 8),
      ibanOficina: this.props.contact.iban.split(" ").join("").slice(8, 12),
      ibanDigitoControl2: this.props.contact.iban
        .split(" ")
        .join("")
        .slice(12, 14),
      ibanNumeroCuenta: this.props.contact.iban
        .split(" ")
        .join("")
        .slice(14, 24),
      emptyFieldMessage: false,
      OKFieldMessage: false,
      certificadoDonacion: this.props.contact.certificadoDonacion,
      direccion: this.props.contact.direccion,
      localidad: this.props.contact.localidad,
      provincia: this.props.contact.provincia,
      cp: this.props.contact.cp,
      toggleCertificate: this.props.contact.certificadoDonacion === "1",
    };
  }

  checkContact = () => {
    if (
      this.state.nombre !== "" &&
      this.state.apellidos !== "" &&
      this.state.dni !== "" &&
      this.state.telefono !== "" &&
      this.state.email !== "" &&
      this.state.ibanCodigoPais !== "" &&
      this.state.ibanCodigoPais.length === 2 &&
      this.state.ibanDigitoControl1 !== "" &&
      this.state.ibanDigitoControl1.length === 2 &&
      this.state.ibanOficina !== "" &&
      this.state.ibanOficina.length === 4 &&
      this.state.ibanEntidad !== "" &&
      this.state.ibanEntidad.length === 4 &&
      this.state.ibanDigitoControl2 !== "" &&
      this.state.ibanDigitoControl2.length === 2 &&
      this.state.ibanNumeroCuenta !== "" &&
      this.state.ibanNumeroCuenta.length === 10 &&
      (this.state.certificadoDonacion === "0" ||
        (this.state.certificadoDonacion === "1" &&
          this.state.direccion !== "" &&
          this.state.localidad !== "" &&
          this.state.provincia !== "" &&
          this.state.cp !== ""))
    ) {
      return true;
    } else return false;
  };

  render() {
    const { onClose, onSaveEditedContact } = this.props;
    return (
      <div className="formblockdatosusuario w-form EditContact-formblockdatosusuario">
        <h5 className="formheader formheaderExtra1">
          Edita la <strong>información del contacto</strong>
        </h5>
        <div className="formdatosusuario">
          <label htmlFor="name" className="comprobarcuponcontenttitle">
            Nombre *
          </label>
          <input
            type="text"
            className="text-field w-input"
            maxLength="256"
            value={this.state.nombre}
            onChange={(e) => {
              this.setState({
                nombre: e.target.value,
              });
            }}
          ></input>
          <label htmlFor="email-3" className="field-label-10">
            Apellidos *
          </label>
          <input
            type="text"
            className="text-field w-input"
            maxLength="256"
            value={this.state.apellidos}
            onChange={(e) => {
              this.setState({
                apellidos: e.target.value,
              });
            }}
          ></input>
          <label htmlFor="email-3" className="field-label-10">
            DNI *
          </label>
          <input
            type="text"
            className="text-field w-input"
            maxLength="256"
            value={this.state.dni}
            onChange={(e) => {
              this.setState({
                dni: e.target.value,
              });
            }}
          ></input>
          <label htmlFor="email-3" className="field-label-11">
            Teléfono *
          </label>
          <input
            type="text"
            className="text-field w-input"
            maxLength="256"
            value={this.state.telefono}
            onChange={(e) => {
              this.setState({
                telefono: e.target.value,
              });
            }}
          ></input>
          <label htmlFor="email" className="field-label-12">
            E-mail *
          </label>
          <input
            type="email"
            className="text-field w-input"
            maxLength="256"
            value={this.state.email}
            onChange={(e) => {
              this.setState({
                email: e.target.value,
              });
            }}
          ></input>
          <label htmlFor="email-3" className="field-label-13">
            IBAN * <span className="message-info">(24 dígitos)</span>
          </label>
          {/*           <input
            type="text"
            id="iban"
            className="text-field w-input"
            maxLength="24"
            value={this.state.iban}
            onChange={(e) => {
              this.setState({
                iban: e.target.value,
              });
            }}
          ></input> */}
          <div className="IBAN-container">
            <input
              type="text"
              className="text-field w-input input-iban-2"
              maxLength="2"
              value={this.state.ibanCodigoPais}
              onChange={(e) => {
                this.setState({
                  ibanCodigoPais: e.target.value,
                });
              }}
            ></input>
            <input
              type="text"
              className="text-field w-input input-iban-2"
              maxLength="2"
              value={this.state.ibanDigitoControl1}
              onChange={(e) => {
                this.setState({
                  ibanDigitoControl1: e.target.value,
                });
              }}
            ></input>
            <input
              type="text"
              className="text-field w-input input-iban-4"
              maxLength="4"
              value={this.state.ibanEntidad}
              onChange={(e) => {
                this.setState({
                  ibanEntidad: e.target.value,
                });
              }}
            ></input>
            <input
              type="text"
              className="text-field w-input input-iban-4"
              maxLength="4"
              value={this.state.ibanOficina}
              onChange={(e) => {
                this.setState({
                  ibanOficina: e.target.value,
                });
              }}
            ></input>
            <input
              type="text"
              className="text-field w-input input-iban-2"
              maxLength="2"
              value={this.state.ibanDigitoControl2}
              onChange={(e) => {
                this.setState({
                  ibanDigitoControl2: e.target.value,
                });
              }}
            ></input>
            <input
              type="text"
              className="text-field w-input input-iban-10"
              maxLength="10"
              value={this.state.ibanNumeroCuenta}
              onChange={(e) => {
                this.setState({
                  ibanNumeroCuenta: e.target.value,
                });
              }}
            ></input>
          </div>
          <label className="w-checkbox checkbox-field-2">
            <input
              type="checkbox"
              className="w-checkbox-input"
              defaultChecked={this.state.toggleCertificate}
              onChange={() => {
                this.setState({
                  certificadoDonacion:
                    this.state.certificadoDonacion === "1" ? "0" : "1",
                });
              }}
            ></input>
            <span className="checkbox-label-2 w-form-label" htmlFor="checkbox">
              ¿Desea recibir certificado de donación a efectos de desgravación
              fiscal?
            </span>
          </label>
          {this.state.certificadoDonacion === "1" && (
            <div>
              <label htmlFor="email-3" className="field-label-13">
                Dirección *
              </label>
              <input
                type="text"
                className="text-field w-input"
                maxLength="24"
                value={this.state.direccion}
                onChange={(e) => {
                  this.setState({
                    direccion: e.target.value,
                  });
                }}
              ></input>
              <label htmlFor="email-3" className="field-label-13">
                Localidad *
              </label>
              <input
                type="text"
                className="text-field w-input"
                maxLength="24"
                value={this.state.localidad}
                onChange={(e) => {
                  this.setState({
                    localidad: e.target.value,
                  });
                }}
              ></input>
              <label htmlFor="email-3" className="field-label-13">
                Provincia *
              </label>
              <input
                type="text"
                className="text-field w-input"
                maxLength="24"
                value={this.state.provincia}
                onChange={(e) => {
                  this.setState({
                    provincia: e.target.value,
                  });
                }}
              ></input>
              <label htmlFor="email-3" className="field-label-13">
                Código Postal *
              </label>
              <input
                type="text"
                className="text-field w-input"
                maxLength="24"
                value={this.state.cp}
                onChange={(e) => {
                  this.setState({
                    cp: e.target.value,
                  });
                }}
              ></input>
            </div>
          )}
          {this.state.OKFieldMessage && (
            <div className="alertmessage warningmessage warningmessageOK">
              <div className="dialogbox">
                Cambios realizados de forma <strong>correcta</strong>.
              </div>
              <img
                src="images/close-grey.svg"
                loading="lazy"
                width="18"
                alt=""
                className="icon"
                onClick={() => {
                  this.setState({ OKFieldMessage: false });
                }}
              />
            </div>
          )}
          {this.state.emptyFieldMessage && (
            <div className="alertmessage warningmessage warningmessageError">
              <div className="dialogbox">
                Existen <strong>campos obligatorios</strong> por rellenar. Por
                favor, <strong>revíselos</strong>.
              </div>
              <img
                src="images/close-grey.svg"
                loading="lazy"
                width="18"
                alt=""
                className="icon"
                onClick={() => {
                  this.setState({ emptyFieldMessage: false });
                }}
              />
            </div>
          )}
          <div className="EditContact-buttons">
            <div
              className="submit-button w-button"
              onClick={() => {
                const editedContact = this.state;
                editedContact.idSolicitud = this.props.contact.idSolicitud;
                editedContact.iban =
                  this.state.ibanCodigoPais +
                  this.state.ibanDigitoControl1 +
                  this.state.ibanEntidad +
                  this.state.ibanOficina +
                  this.state.ibanDigitoControl2 +
                  this.state.ibanNumeroCuenta;
                if (this.checkContact()) {
                  this.setState({
                    OKFieldMessage: true,
                    emptyFieldMessage: false,
                  });
                  onSaveEditedContact(editedContact);
                } else {
                  this.setState({
                    emptyFieldMessage: true,
                    OKFieldMessage: false,
                  });
                }
              }}
            >
              <span>Guardar</span>
            </div>
            <div className="submit-button w-button" onClick={onClose}>
              <span>Cerrar</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditContact;
